<template>
  <div>
    <div class="section section__company">
      <div class="section__wrapper">
        <div class="title-with-icon title-with-icon_menu mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_back"></div>
          <div class="title-with-icon__title">
            <a href="/sveden">Сведения об образовательной организации</a>
          </div>
          <div class="title-with-icon__delimeter">/</div>
          <div class="title-with-icon__title">Образование</div>
        </div>

        <div class="content_title content_700 mb-24">Лицензия:</div>
        <div class="content_18 mb-24">
          № 039744 серия 77Л01, номер бланка 0010625, выдана Департаментом образования города Москвы 19 ноября 2018 г. Срок действия: <strong class="content_700">бессрочно</strong>
        </div>

        <div class="container-grid grid-3 grid-md-1 mb-24">
          <div class="container-grid__item">
            <div class="doc fill-white p-24 container-shadow">
              <div class="doc__content row">
                <div class="col-auto text-center">
                  <div class="ext-wrapper m-0">
                    <img src="/pic/icon-doc.svg"/>

                  </div>
                </div>
                <div class="col">
                  <div class="doc__title">Лицензия на осуществление образовательной деятельности (с приложениями)</div>
                </div>
              </div>
              <div class="doc__actions">
                <a itemprop="licenseDocLink" href="https://exporteduru.servicecdn.ru/media/attachment/0001/41/b14b2918cf8ca193f271dde14209d3183ded5f0a.pdf" class="btn btn-outline-primary">Скачать</a>
              </div>
            </div>
          </div>
        </div>

        <div class="content_title content_700 mb-24">Основные направления подготовки слушателей:</div>
        <div class="content_18 mb-5">
          <ul class="list list_dotted">
            <li>программы повышения квалификации реализуются в объеме от 16 до 250 часов. Обучающимся, успешно освоившим программу и прошедшим итоговую аттестацию, выдается удостоверение о повышении квалификации (ссылка на удостоверение)</li>
            <li>программы профессиональной переподготовки реализуются в объеме более 250 часов. Обучающимся, успешно освоившим программу и прошедшим итоговую аттестацию, выдается диплом о профессиональной переподготовке (ссылка на диплом)</li>
          </ul>
        </div>

        <div class="content_title content_700 mb-24" itemprop="eduAccred">Формы обучения</div>
        <div class="content_18 mb-4">
          <div class="mb-1">Формы обучения — <strong class="content_700" itemprop="eduForm">очная, заочная, очно-заочная формы обучения, а также применяются электронное образование и дистанционные образовательные технологии.</strong></div>
          <div class="mb-1">Языки, на которых осуществляется обучение (обучение) — <strong class="content_700" itemprop="language">русский</strong></div>
          <div class="mb-1">Реализуемые уровни образования — <strong class="content_700" itemprop="eduLevel">дополнительное профессиональное образование.</strong></div>
          <div class="mb-1">Нормативные сроки обучения — <strong class="content_700" itemprop="learningTerm">в соответствие со сроками, указанными в договоре об оказании образовательных услуг.</strong></div>
        </div>

        <div class="notice notice_red mb-5">
          <div class="content_title content_700 mb-24">Описание образовательной программы</div>
          <div class="notice__content content_18">
            <div class="mb-1">Дополнительное профессиональное образование в Школе экспорта РЭЦ осуществляется посредством реализации дополнительных профессиональных программ, направленных на:</div>
            <ul class="mb-1 list list_dotted">
              <li>совершенствование и получение новой компетенции, необходимой для профессиональной деятельности и (или) повышения профессионального уровня в рамках имеющейся квалификации;</li>
              <li>получение компетенции, необходимой для выполнения нового вида профессиональной деятельности, приобретение новой квалификации.</li>
            </ul>
            <div>Содержание дополнительного профессионального образования определяется образовательными программами, разработанными и утвержденными Школой экспорта РЭЦ.</div>
          </div>
        </div>

        <div class="container-shadow fill-white p-24 pb-0 mb-5">
          <div class="content_18 content_blue_light mb-1">Реализуемые образовательные программы</div>
          <div class="content_title content_700 mb-24" itemprop="eduOp">Организация экспортной деятельности российских предприятий для тренеров	</div>

          <div class="content_18 content_blue_light mb-1">Форма обучения</div>
          <div class="content_18 mb-24" itemprop="eduForm">Очная с применением дистанционных образовательных технологий	</div>

          <div class="education__company-courses d-flex">
            <div class="section__company-courses-table">
              <div class="table-wrapper mb-24">
                <div class="content_18 content_blue_light mb-2">Курсы образовательной программы</div>
                <div class="education-table">
                  <table class="table_bordered table_cell_unborded">
                    <tbody>
                    <tr>
                      <td><strong class="content_700">Курс 1.</strong> Введение в экспорт: жизненный цикл экспортного проекта. Государственная поддержка экспортно ориентированных предприятий для тренеров</td>
                      <td><strong class="content_700 text-nowrap">22 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 2.</strong>Эффективный маркетинг для экспортеров для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 3.</strong> Деловая коммуникация в экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 4.</strong> Правовое обеспечение экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 5.</strong> Управление финансовыми ресурсами для тренеров</td>
                      <td><strong class="content_700 text-nowrap">22 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 6.</strong> Документационное обеспечение экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 7.</strong> Таможенное оформление экспортных операций для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 8.</strong> Логистика в экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 9.</strong> Возможности онлайн-торговли для экспортеров для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 10.</strong> Налоговые аспекты экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 11.</strong> Поддержка экспортеров: Продукты группы Российского экспортного центра для тренеров</td>
                      <td><strong class="content_700 text-nowrap">26 акад.ч.</strong></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="section__company-courses-docs">
              <div class="content_18 content_blue_light mb-2">Описание образовательной программы</div>
              <div class="container-grid grid-1">
                <div class="container-grid__item">
                  <div class="doc fill-white p-24 container-shadow">
                    <div class="doc__content row">
                      <div class="col-auto text-center">
                        <div class="ext-wrapper m-0">
                          <img src="/pic/icon-doc.svg"/>

                        </div>
                      </div>
                      <div class="col">
                        <div class="doc__title">Описание образовательной программы повышения квалификации Организация экспортной деятельности российских предприятий для тренеров</div>
                      </div>
                    </div>
                    <div class="doc__actions">
                      <a itemprop="opMain" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/05ffb0e63f728953b89c521a3856eca428aec0c4.pdf" class="btn btn-outline-primary">Скачать</a>
                    </div>
                  </div>
                </div>
                <div class="container-grid__item">
                  <div class="doc fill-white p-24 container-shadow">
                    <div class="doc__content row">
                      <div class="col-auto text-center">
                        <div class="ext-wrapper m-0">
                          <img src="/pic/icon-doc.svg"/>

                        </div>
                      </div>
                      <div class="col">
                        <div class="doc__title">Аннотация образовательной программы повышения квалификации Организация экспортной деятельности российских предприятий для тренеров</div>
                      </div>
                    </div>
                    <div class="doc__actions">
                      <a itemprop="educationAnnotation" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/f487a628841e2252d16a7f9f33ec6a3b710554a2.pdf" class="btn btn-outline-primary">Скачать</a>
                    </div>
                  </div>
                </div>
                <div class="container-grid__item">
                  <div class="doc fill-white p-24 container-shadow">
                    <div class="doc__content row">
                      <div class="col-auto text-center">
                        <div class="ext-wrapper m-0">
                          <img src="/pic/icon-doc.svg"/>

                        </div>
                      </div>
                      <div class="col">
                        <div class="doc__title">Учебный план образовательной программы повышения квалификации &laquo;Организация экспортной деятельности российских предприятий для тренеров&raquo;</div>
                      </div>
                    </div>
                    <div class="doc__actions">
                      <a itemprop="educationPlan" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/2034cddf217be44fbf84192ae29400644910f6fd.pdf" class="btn btn-outline-primary">Скачать</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-shadow fill-white p-24 pb-0 mb-5">
          <div class="content_18 content_blue_light mb-1">Реализуемые образовательные программы</div>
          <div class="content_title content_700 mb-24" itemprop="eduOp">Организация экспортной деятельности российских предприятий для слушателей</div>

          <div class="content_18 content_blue_light mb-1">Форма обучения</div>
          <div class="content_18 mb-24" itemprop="eduForm">Очная</div>

          <div class="education__company-courses d-flex">
            <div class="section__company-courses-table">
              <div class="content_18 content_blue_light mb-2">Курсы образовательной программы</div>
              <div class="table-wrapper mb-24">
                <div class="education-table">
                  <table class="table_bordered table_cell_unborded">
                    <tbody>
                    <tr>
                      <td><strong class="content_700">Курс 1.</strong> Введение в экспорт: жизненный цикл экспортного проекта. Государственная поддержка экспортно ориентированных предприятий для тренеров</td>
                      <td><strong class="content_700 text-nowrap">22 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 2.</strong> Эффективный маркетинг для экспортеров для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 3.</strong> Деловая коммуникация в экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 4.</strong> Правовое обеспечение экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 5.</strong> Управление финансовыми ресурсами для тренеров</td>
                      <td><strong class="content_700 text-nowrap">22 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 6.</strong> Документационное обеспечение экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 7.</strong> Таможенное оформление экспортных операций для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 8.</strong> Логистика в экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 9.</strong> Возможности онлайн-торговли для экспортеров для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 10.</strong> Налоговые аспекты экспортной деятельности для тренеров</td>
                      <td><strong class="content_700 text-nowrap">16 акад.ч.</strong></td>
                    </tr>
                    <tr>
                      <td><strong class="content_700">Курс 11.</strong> Поддержка экспортеров: Продукты группы Российского экспортного центра для тренеров</td>
                      <td><strong class="content_700 text-nowrap">26 акад.ч.</strong></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="section__company-courses-docs">
              <div class="content_18 content_blue_light mb-2">Описание образовательной программы</div>
              <div class="container-grid grid-1">
                <div class="container-grid__item">
                  <div class="doc fill-white p-24 container-shadow">
                    <div class="doc__content row">
                      <div class="col-auto text-center">
                        <div class="ext-wrapper m-0">
                          <img src="/pic/icon-doc.svg"/>

                        </div>
                      </div>
                      <div class="col">
                        <div class="doc__title">Описание образовательной программы повышения квалификации Организация экспортной деятельности российских предприятий для слушателей</div>
                      </div>
                    </div>
                    <div class="doc__actions">
                      <a itemprop="opMain" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/363865a2f0bb8491b5a0390dbf741f377c5d5cfe.pdf" class="btn btn-outline-primary">Скачать</a>
                    </div>
                  </div>
                </div>
                <div class="container-grid__item">
                  <div class="doc fill-white p-24 container-shadow">
                    <div class="doc__content row">
                      <div class="col-auto text-center">
                        <div class="ext-wrapper m-0">
                          <img src="/pic/icon-doc.svg"/>

                        </div>
                      </div>
                      <div class="col">
                        <div class="doc__title">Аннотация образовательной программы повышения квалификации Организация экспортной деятельности российских предприятий для слушателей</div>
                      </div>
                    </div>
                    <div class="doc__actions">
                      <a itemprop="educationAnnotation" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/cb0b59573e57b7603dcd757e7b6ba6d7068005d2.pdf" class="btn btn-outline-primary">Скачать</a>
                    </div>
                  </div>
                </div>
                <div class="container-grid__item">
                  <div class="doc fill-white p-24 container-shadow">
                    <div class="doc__content row">
                      <div class="col-auto text-center">
                        <div class="ext-wrapper m-0">
                          <img src="/pic/icon-doc.svg"/>

                        </div>
                      </div>
                      <div class="col">
                        <div class="doc__title">Учебный план образовательной программы повышения квалификации «Организация экспортной деятельности российских предприятий для слушателей</div>
                      </div>
                    </div>
                    <div class="doc__actions">
                      <a itemprop="educationPlan" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/533ac2a28ac03661e296066399cd079889a7dd86.pdf" class="btn btn-outline-primary">Скачать</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-shadow fill-white p-24 pb-0 mb-5">
          <div class="content_18 content_blue_light mb-1">Реализуемые образовательные программы</div>
          <div class="content_title content_700 mb-24" itemprop="eduOp">Старт экспортной деятельности: 10 шагов к успеху</div>

          <div class="content_18 content_blue_light mb-1">Форма обучения</div>
          <div class="content_18 mb-24" itemprop="eduForm">Заочная с применением дистанционных образовательных технологий</div>

          <div class="content_18 content_blue_light mb-1">Объем обучения</div>
          <div class="content_18 mb-24 content_700">100 акад.ч.</div>

          <div class="content_18 content_blue_light mb-2">Описание образовательной программы</div>

          <div class="container-grid grid-3 grid-md-1">
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Описание образовательной программы повышения квалификации «Старт экспортной деятельности 10 шагов к успеху»</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a itemprop="opMain" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/2783ac10fa06e3b7f19698e8b2169ed4eeca87fa.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Аннотация образовательной программы повышения квалификации «Старт экспортной деятельности 10 шагов к успеху»</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a itemprop="educationAnnotation" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/17514962235b364c8119ff82a4908d44366fe686.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Учебный план образовательной программы повышения квалификации «Старт экспортной деятельности 10 шагов к успеху»</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a itemprop="educationPlan" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/a3ae4a6e9225b9b7c07c022dc40f6323905a014b.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content_title content_700 mb-4">Методические и иные документы</div>
        <div class="mb-10">
          <div class="container-grid grid-3 grid-md-1">
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение об организации и осуществления обр. деятельности по ДПП</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0001/79/f8a1e7cd35b978f78c8ac67a03a7b13d7e6f7788.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение об организации и осуществления обр. деятельности по ДПП - ЭЦП</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/48994dd3951cbb9ee62b10f885e6dba861f13b67.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение об осуществлении текущ. контроля и промежуточной аттестации</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0001/10/fe413edd34b6fb0c1f7fc75a64291f58d521dd1a.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение об осуществлении текущ. контроля и промежуточной аттестации - ЭЦП</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/7ca5e7bde3d89366f05d38529e6dfff830707c33.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение об итоговой аттестации</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/document/0001/11/b2a60364ba00ec14293ce7636baee48e783a6401.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение об итоговой аттестации - ЭЦП</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/7ca5e7bde3d89366f05d38529e6dfff830707c33.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Приказ о порядке заполнения, учета и выдачи документов об образовании и о квалификации</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/default/0001/13/7131f3736fbbfee3c82299f72570e68812a55daa.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Приказ о порядке заполнения, учета и выдачи документов об образовании и о квалификации - ЭЦП</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/3d8eb09a75cda4598b90efc44570ed7f6015f110.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение о порядке заполнения, учета и выдачи документов об образовании и о квалификации</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/document/0001/11/137d4a6ae99a1cfc69f272e05ca36763935a61d8.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение о применении электронного обучения и дистанционных обр. технологий</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0001/16/5236cec52441e9d24362ff70a21d2e59cea18d8e.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение об информационной открытости</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0001/01/ec2ceef14f7cc9670b36c6593ca4ae25323734f9.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Численность обучающихся - ЭЦП</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a itemprop="eduChislen" href="https://exporteduru.servicecdn.ru/media/attachment/0002/64/add935da9a8d82146e8aa38056be63fa9b14c556.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Календарный учебный график на 2022 год</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a itemprop="educationShedule" href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/db0cee41acb4886cdd608fbf4d04d411d8e68741.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Календарный учебный график на 2023 год</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a itemprop="educationShedule" href="https://exporteduru.servicecdn.ru/media/default/0002/92/cc962cdff86f0c580a0f69913cb1c30b2f425cc7.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение о модульно-накопительной системе повышения квалификации</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0001/79/6a5f815c78b2af36e5aceda1c777a52f894193b6.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение о модульно-накопительной системе повышения квалификации - ЭЦП</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/6ff98d12c829f0564568ce5729bc65dd4650aea7.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение о порядке подтверждения квалификации региональных тренеров Школы экспорта</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0001/79/bb5d2ede642834e1a3e195c58f34e62e09cbeeab.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
            <div class="container-grid__item">
              <div class="doc fill-white p-24 container-shadow">
                <div class="doc__content row">
                  <div class="col-auto text-center">
                    <div class="ext-wrapper m-0">
                      <img src="/pic/icon-doc.svg"/>

                    </div>
                  </div>
                  <div class="col">
                    <div class="doc__title">Положение о порядке подтверждения квалификации региональных тренеров Школы экспорта - ЭЦП</div>
                  </div>
                </div>
                <div class="doc__actions">
                  <a href="https://exporteduru.servicecdn.ru/media/attachment/0002/60/036ed09bf58873163a74130639690304f1c963ff.pdf" class="btn btn-outline-primary">Скачать</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-5 d-block d-md-none">
          <a class="history-back" href="/sveden">Вернуться</a>
        </div>
        <div class="section__subsection_blue section__company-nav">
          <div class="section__subsection-wrapper">
            <company-navigation />
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../../components/PageFooter";
import ApiService from "../../../services/api.service";
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoEdu",
  components: {CompanyNavigation, PageFooter},
  data() {
    return {
      blocks: null
    }
  },
  mounted() {
    ApiService.get('infocategories/education').then(res => {
      this.blocks = res.data.info_blocks
      this.$parent.init()
    })
  }
}
</script>

<style lang="scss">
  .section__company {
    h4 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-top: 7rem!important;
      &.no-margin {
        margin: -40px 0 20px!important;
      }
    }
    .content_18 {
      a {
        color: #ed1b2f;
        text-decoration: underline;
      }
    }
  }
</style>